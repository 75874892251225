import React from "react";
import { withTranslation } from "react-i18next";
import { cicassService, auth } from "../../../_services";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  AccordionComponent,
  AccordionItemDirective,
  AccordionItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { DateTime } from "luxon";
import AttendanceDialog from "./AttendanceDialog";
import AndroidIcon from "@mui/icons-material/Adb";
import AppleIcon from "@mui/icons-material/Apple";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";

const apiEndpointAttendance = "attendance/detection";
const apiUrlNews = "board/dash";
const apiUrlSetting = "setting";

class Home extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      didMountCompleted: false,
      geolocation: [],
      boardData: [],
      disableAttendance: "1",
      toastInfo: {
        title: "",
        content: "Succesfully",
        cssClass: "e-toast-success",
        icon: "e-success toast-icons",
      },
      isTimbratureDialogOpen: false,
      isSubmitDisabled: false,
      disableTimbraButton: false,
    };
    this.position = { X: "Right", Y: "Bottom" };
    this.getGeolocation = this.getGeolocation.bind(this);
    this.saveAttendance = this.saveAttendance.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    this.getGeolocation();
    let { data: boardData } = await cicassService.getAll(apiUrlNews);
    let { data: mySetting } = await cicassService.getAll(
      apiUrlSetting + "/DisableAttendance"
    );
    if (this._isMounted) {
      this.setState({
        didMountCompleted: true,
        boardData,
        disableAttendance: mySetting.value === "1" ? "1" : "0",
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleDialogClose() {
    this.setState({
      isTimbratureDialogOpen: false,
      isSubmitDisabled: false,
    });

    setTimeout(() => {
      this.setState({
        disableTimbraButton: false,
      });
    }, 1000);
  }

  getGeolocation() {
    const weblang = localStorage.getItem("weblang");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async position => {
        // console.log(
        //   "From DEVICE: - Latitude: " +
        //     position.coords.latitude +
        //     ", - Longitude: " +
        //     position.coords.longitude
        // );
        auth
          .checkGeoLocation(
            position.coords.latitude,
            position.coords.longitude,
            weblang
          )
          .then(response => {
            const geolocation = { ...response.data };
            this.setState({ geolocation });
          });
      });
    } else {
      console.log("No geolocation !!!");
      this.setState({ geolocation: [] });
    }
  }

  async saveAttendance(message, attendanceCause, attendanceNote) {
    try {
      // console.log(attendanceCause, attendanceNote);
      const format = "yyyy-MM-dd'T'HH:mm:ss";
      const currentDate = DateTime.now().toFormat(format);
      let dataToUpdate = {
        id: -1,
        anaUserId: -1,
        fromDt: currentDate,
        toDt: null,
        anaAttendanceTypeId: attendanceCause,
        attendanceNote,
      };
      await cicassService.saveOp(apiEndpointAttendance, dataToUpdate);
      // console.log(response);
      this.setToastInfo("Success", message);
      this.getGeolocation();
    } catch (error) {
      this.setState({ isSubmitDisabled: true });
      throw new Error("Impossibile aggiornare riga");
    }
  }

  lastContent(t) {
    let { geolocation, disableTimbraButton } = this.state;
    // geolocation = undefined;
    // console.log(geolocation);
    const androidActivateGeolocationPage =
      "https://support.google.com/nexus/answer/3467281?hl=it#:~:text=Apri%20l%27app%20Impostazioni%20del,disattiva%20Accesso%20alla%20mia%20posizione.";
    const appleActivateGeolocationPage =
      "https://support.apple.com/it-it/HT207092";

    return (
      <div id="geolocation">
        {geolocation !== undefined && geolocation.enabled && (
          <ButtonComponent
            cssClass="e-info e-outline"
            onClick={() => {
              this.setState({
                disableTimbraButton: true,
                isTimbratureDialogOpen: true,
              });
            }}
            disabled={disableTimbraButton}>
            {t("stamp")}
          </ButtonComponent>
        )}
        {geolocation !== undefined && geolocation.lastOperation !== null && (
          <Box
            sx={{
              mt: 1,
            }}>
            <Typography
              sx={{
                fontWeight: "500",
              }}>
              {geolocation.lastOperation}
            </Typography>
          </Box>
        )}
        {(geolocation === undefined || !geolocation.enabled) && (
          <section>
            <p> ℹ️ {t("geoActivation")}</p>

            <div>
              <Tooltip title={t("attendanceAndroidTooltip")}>
                <IconButton
                  onClick={() =>
                    window.open(androidActivateGeolocationPage, "_blank")
                  }>
                  <AndroidIcon />
                </IconButton>
              </Tooltip>

              <Tooltip
                title={t("attendanceAppleTooltip")}
                onClick={() =>
                  window.open(appleActivateGeolocationPage, "_blank")
                }>
                <IconButton>
                  <AppleIcon />
                </IconButton>
              </Tooltip>
            </div>
          </section>
        )}
      </div>
    );
  }

  setToastInfo(title, errorMsg) {
    let { toastInfo } = this.state;
    switch (title) {
      case "Warning":
        toastInfo.title = "Warning";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-warning";
        toastInfo.icon = "e-warning toast-icons";
        break;
      case "Success":
        toastInfo.title = "Success";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-success";
        toastInfo.icon = "e-success toast-icons";
        break;
      case "Error":
        toastInfo.title = "Error";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-danger";
        toastInfo.icon = "e-error toast-icons";
        break;
      case "Information":
        toastInfo.title = "Information";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-info";
        toastInfo.icon = "e-info toast-icons";
        break;
      default:
        break;
    }
    this.setState({ toastInfo });
    this.toastObj.show();
  }

  render() {
    const { t } = this.props;
    let { boardData, disableAttendance } = this.state;

    return (
      <React.Fragment>
        <div style={{ margin: "1.5rem" }}>
          <h2 className="text-gray-800 text-xl font-semibold">{t("news")}</h2>
        </div>
        <div className="p-4 flex-auto">
          <AccordionComponent>
            <AccordionItemsDirective>
              {boardData.length > 0 &&
                boardData.map((item, idx) => {
                  let dM = new Date(item.createdAt);
                  return (
                    <AccordionItemDirective
                      key={idx}
                      header={
                        item.subject +
                        " | " +
                        DateTime.fromJSDate(dM).toFormat("dd/MM/yyyy")
                      }
                      content={item.main.replace(/(?:\r\n|\r|\n)/g, "<br>")}
                      expanded={false}
                    />
                  );
                })}
            </AccordionItemsDirective>
          </AccordionComponent>
          <ToastComponent
            ref={toast => {
              this.toastObj = toast;
            }}
            id="toast_default"
            position={this.position}
            title={this.state.toastInfo.title}
            content={this.state.toastInfo.content}
            cssClass={this.state.toastInfo.cssClass}
            icon={this.state.toastInfo.icon}
          />
        </div>
        {disableAttendance == "0" && (
          <>
            <div style={{ margin: "1rem" }}>
              <h2 className="text-gray-800 text-xl font-semibold">
                {t("presences")}
              </h2>
            </div>
            <div className="pl-4">{this.lastContent(t)} </div>
          </>
        )}

        <AttendanceDialog
          isOpen={this.state.isTimbratureDialogOpen}
          handleClose={this.handleDialogClose}
          onSubmit={(attendanceCause, attendanceNote) => {
            this.setState({ isSubmitDisabled: true });
            this.saveAttendance(this.props.t("toastSuccess"), attendanceCause, attendanceNote);
            this.handleDialogClose();
          }}
          translate={this.props.t}
          isSubmitDisabled={this.state.isSubmitDisabled}
        />
      </React.Fragment>
    );
  }
}

export default withTranslation()(Home);
